*
{
  padding: 0px;
  margin: 0px;
}
.App
{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
main
{
  display: flex;
  flex-grow: 1;
  justify-content: space-around;
}
header
{
  min-height: 180px;
  background: rgba(0, 0, 0, 0.37);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
}
.container
{
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
.clockFace
{
  display: flex;
  background-color: rgb(0, 0, 0);
  justify-content: center;
  align-items: center;

}
