.blockPoints
{
  display: flex;  
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}
.points
{
  margin: 100% 0px ;
  width:  1vw;
  height: 1vw;
  border-radius: 50%;
  background-color: rgb(58, 57, 57); 
}
.tick
{
  background-color: greenyellow;
  box-shadow: 0px 0px 20px white;
}