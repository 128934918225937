footer
{    
    /*flex-direction: row;*/
    display: flex;
    min-height: 180px;
    background: rgba(0, 0, 0, 0.37);
    padding: 5px;
    justify-content: space-around;
    flex-wrap: wrap;
}
footer .box
{
  display: flex;
  flex-direction: column;
  align-self :center;
}

footer .box a img
{
    margin-right: 10px;
    height: 40px;
    cursor: pointer;

}
/*@media only screen and (max-width: 600px) {
  img {
    max-width: 60px;
  }
}*/
footer a
{
    font-size: 15px;
    margin: 5px;
    /*margin-left: 10%;*/
    display: flex;
    align-items: center;

}